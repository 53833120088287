import Darren from './images/darren.jpg'
import Mike from './images/mike.jpg'
import Steve from './images/steve.jpg'
import Jenny from './images/jenny.jpg'

export interface Manager{
    name: string
    email: string
    image: string
    role: string
}

const managers: Manager[] = [{
    name: 'Darren Barnfield',
    email: 'darren@ssbrightdrawers.co.uk',
    image: Darren,
    role: 'Managing Director'
}, {
    name: 'Mike Barnfield',
    email: 'mike.barnfield@ssbrightdrawers.co.uk',
    image: Mike,
    role: 'Executive Director'
}, {
    name: 'Steve Perry',
    email: 'steve@ssbrightdrawers.co.uk',
    image: Steve,
    role: 'Production Manager'
}, {
    name: 'Jenny Barnfield',
    email: 'jenny@ssbrightdrawers.co.uk',
    image: Jenny,
    role: 'Office Administrator'
}]

export default managers