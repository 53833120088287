import React from 'react'
import ContentPage from './ContentPage'
import EnquiryForm from './EnquiryForm'
import {Typography} from '@material-ui/core'
import HText from './HText'
import Address from './Address'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles'

const styles = ({breakpoints, palette}: Theme) => createStyles({
  container: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    gridGap: '2em',
    padding: '1em',
    [breakpoints.up('md')]: {
      gridTemplateRows: 'auto',
      gridTemplateColumns: '1fr 2fr'
    },
    [breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 1fr 3fr 1fr'
    }
  },
  enquiry: {
    gridRowStart: 2,
    [breakpoints.up('md')]: {
      padding: '0 1em',
      gridRowStart: 1,
      gridColumnStart: 2,
      borderLeft: '1px solid rgb(0,0,0,0.1)'
    },
    [breakpoints.up('xl')]: {
      gridColumnStart: 3,
    }
  },
  address: {
    gridRowStart: 1,
    gridColumnStart: 1,
    [breakpoints.up('xl')]: {
      gridColumnStart: 2,
    }
  }
})

const Contact: React.SFC<WithStyles<typeof styles>> = ({classes}) => {
  const address = <div className={classes.address}>
    <Typography variant='h4' component='h2' gutterBottom color='primary'>Our Details</Typography>
    <Address/>
    <Typography>Phone: <HText>01142 700695</HText></Typography>
    <Typography>Email: <HText>darren@ssbrightdrawers.co.uk</HText></Typography>
  </div>

  return <ContentPage title='Contact Us'>
    <div className={classes.container}>
        {address}
        <EnquiryForm className={classes.enquiry}/>
    </div>
    
  </ContentPage>
}

export default withStyles(styles)(Contact)
