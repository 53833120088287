import React, { Component, CSSProperties } from 'react'
import { Typography, MenuItem, Grid, Button, Snackbar, TextField } from '@material-ui/core';

const cardMessage = 'Have a question about our services? Call us on 01142 700695 or send us an e-mail to: darren@ssbrightdrawers.co.uk or send us a message using the form below and we\'ll be happy to help you out.'

interface State {
  name: string
  message: string
  replyMethod: 'email' | 'phone'
  replyDetails: string
  submissionSuccess: boolean
  submissionError: boolean
}

interface Props {
  style?: CSSProperties,
  className?: string
}
class EnquiryForm extends Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      name: '',
      message: '',
      replyMethod: 'email',
      replyDetails: '',
      submissionSuccess: false,
      submissionError: false
    }
  }

  render() {
    const nameField = <TextField
      fullWidth
      required
      label='Your name'
      name='name'
      onChange={(event) => this.setState({name: event.currentTarget.value})}
      value={this.state.name}
    />

    const messageField = <TextField
      fullWidth
      multiline
      required
      label='What would you like to ask us?'
      name='message'
      rows={4}
      rowsMax={20}
      onChange={(event) => this.setState({message: event.currentTarget.value})}
      value={this.state.message}
    />

    const replyMethod = <TextField
      value={this.state.replyMethod}
      fullWidth
      label='How should we get back to you?'
      select
      name='replyMethod'
      onChange={(event) => this.setState({
        replyMethod: event.target.value as 'email'|'phone',
        replyDetails: ''
      })}
    >
      <MenuItem key='email' value='email'>Email</MenuItem>
      <MenuItem key='phone' value='phone'>Phone</MenuItem>
    </TextField>

    if(this.state.replyMethod === 'email') {
      
    }
    
    const replyDetailsProps = {
      email: {
        label:'Email address'
      },
      phone: {
        label: 'Phone number'
      }
    }
    const replyDetails = <TextField
      value={this.state.replyDetails}
      fullWidth
      required
      name='replyDetails'
      onChange={event => this.setState({replyDetails: event.currentTarget.value})}
      {...replyDetailsProps[this.state.replyMethod]}
    />

    const submitButton = <Button
      type='submit'
      variant='contained'
      color='primary'
    >
        Send Message
    </Button>

    return <div className={this.props.className} style={this.props.style}>
      <Typography variant='h4' component='h2' color='primary' gutterBottom>Make an enquiry</Typography>
      <form onSubmit={this.submitForm}>
        <Grid container spacing={24} justify='center'>
          <Grid item xs={12}><Typography>{cardMessage}</Typography></Grid>
          <Grid item xs={12}>{nameField}</Grid>
          <Grid item xs={12}>{messageField}</Grid>
          <Grid item xs={12} sm={6} lg={4}>{replyMethod}</Grid>
          <Grid item xs={12} sm={6} lg={4}>{replyDetails}</Grid>
          <Grid item container xs={12} md={4} justify='center' alignItems='center'>{submitButton}</Grid>
        </Grid>
      </form>
      <Snackbar
        open={this.state.submissionSuccess}
        autoHideDuration={5000}
        onClose={() => this.setState({submissionSuccess: false})}
        message={'Thank you for your enquiry! We\'ll get back to you as soon as possible.'}
      />
      <Snackbar
        open={this.state.submissionError}
        autoHideDuration={5000}
        onClose={() => this.setState({submissionError: false})}
        message='Something went wrong with the request, please try again.'
      />
    </div>
  }

  private submitForm = (event: any) => {
    event.preventDefault()
    const data = {
      name: this.state.name,
      message: this.state.message,
      replyMethod: this.state.replyMethod,
      replyDetails: this.state.replyDetails
    }
    fetch('https://u9rqqe3opk.execute-api.eu-west-2.amazonaws.com/prod/ssbd-contact', {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'no-cors'
    }).then(response => {
      this.setState({
        name: '',
        message: '',
        replyMethod: 'email',
        replyDetails: '',
        submissionSuccess: true,
        submissionError: false
      })
    }).catch(() => this.setState({submissionError: true}))
  }
}

export default EnquiryForm