import React, {CSSProperties} from 'react'
import {Typography} from '@material-ui/core'
import HText from './HText'

interface Props {
  style?: CSSProperties
}

const Address: React.SFC<Props> = ({style}) =><Typography style={style}> 
  <HText>SS Bright Drawers (Sheffield) Ltd,</HText> <br/>
  Worthing Road, <br/>
  Sheffield. <br/>
  S9 3JB.
</Typography>

export default Address