import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles'
import SocialMediaLinks from './SocialMediaLinks';
import { Typography } from '@material-ui/core';
import HText from './HText'
import Address from './Address'

const styles = ({palette, breakpoints}: Theme) => createStyles({
  container: {
    color: 'white',
    backgroundColor: palette.primary.dark,
    padding: '2em',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
    gridRowGap: '2em',
    [breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr auto 1fr'
    }
  },
  address: {
    gridRowStart: 2,
    color: 'inherit',
    [breakpoints.up('sm')]: {
      gridRowStart:1
    }
  },
  socialMedia: {
    gridRowStart: 1,
    [breakpoints.up('sm')]: {
      gridColumnStart:3,
      justifySelf: 'end'
    }
  }
})

const Footer: React.SFC<WithStyles<typeof styles>> = ({classes}) => <div className={classes.container}>
  <div className={classes.address}>
    <Address style={{color: 'inherit'}}/>
    <Typography style={{color: 'inherit'}}>Phone: <HText>01142 700695</HText></Typography>
    <Typography style={{color: 'inherit'}}>Email: <HText>darren@ssbrightdrawers.co.uk</HText></Typography>
  </div>
  <SocialMediaLinks className={classes.socialMedia}/>
</div>

export default withStyles(styles)(Footer)